import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormuleVariableMultichoiceComponent } from "./formule-variable-multichoice/formule-variable-multichoice.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { FormulasComponent } from "./formulas/formulas.component";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../guards/auth-guard.service";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { NgMathPipesModule } from "ngx-pipes";
import { MatInputModule } from "@angular/material/input";

const routes: Routes = [
	{
		path: "",
		canActivate: [AuthGuardService],
		component: FormulasComponent,
		pathMatch: "full",
	},
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatDialogModule,
		MatRadioModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatFormFieldModule,
		MatIconModule,
		MatSelectModule,
		NgMathPipesModule,
		MatInputModule,
		FormuleVariableMultichoiceComponent,
		FormulasComponent,
	],
	exports: [FormulasComponent],
})
export class FormulasModule {}
